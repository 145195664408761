<template>
  <v-container style="display: block;">
    <stepper :value="3" :complete="2" />
    <question
      @fileLoaded="test($event)"
      :disable="true"
      type="base"
      label="Choose a background sound clip"
    />
    <v-row>
      <h4 class="ma-10">
        {{ $t("Choose the style of displaying the question") }}
      </h4>
    </v-row>
    <v-row
      style="width: 65%;margin: auto;"
      class="justify-space-around align-center"
    >
      <v-col v-for="i in 3" :key="i" lg="3">
        <div class="display_style">
          <v-icon size="100" color="primary">mdi-image</v-icon>
        </div>
        <v-row class="ma-2 justify-center">
          <v-radio-group column>
            <v-radio color="primary" value="primary"></v-radio>
          </v-radio-group>
        </v-row>
      </v-col>
    </v-row>

    <btns :action="postExercise" :loader="loader" />
  </v-container>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
export default {
  data() {
    return {
      items: 2,
      Exercise,
    };
  },
  methods: {
    test(file) {},
    postExercise() {
      if (Exercise.postType == "edit") {
        Exercise.put(Exercise.exercise, Exercise.exercise.id);
      } else {
        Exercise.post(Exercise.exercise);
      }
    },
  },
  computed: {
    loader() {
      return Exercise.postState.loading;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";

.add-box {
  width: 20%;
  height: 150px;
  margin: auto;
  border: 1px solid grey;
  cursor: pointer;
}
.icon {
  width: 50% !important;
  height: 50% !important;
  color: grey !important;
}
.drag-drob {
  height: 200px;
  border: 1px solid grey;
  margin: auto;
}
.display_style {
  height: 200px;
  border: 1px solid $myBlue;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
